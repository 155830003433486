import { Component, OnInit } from '@angular/core';

import { SelectItem } from 'primeng/api';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { ProductService } from 'src/app/demo/service/productservice';
import { EventService } from 'src/app/demo/service/eventservice';
import { Product } from 'src/app/demo/domain/product';
import { ApiService, AuthService } from 'src/app/service';
import { AlertService } from 'src/app/service/component/alert/alert.service';
import * as moment from 'moment';
import { AppBreadcrumbService } from 'src/app/app.breadcrumb.service';
import { PrimeIcons } from "primeng/api";
@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
    item: any = {
        date: [
            new Date(new Date().setDate(new Date().getDate() - 7))
            , new Date()],
    };
    now: any = moment(new Date()).format('YYYY MMMM DD')
    timelineEvents: any[];
    listTransaction:any =[]
    visitorChart: any;
    chartOptions: any;
    chartOptions2: any;
    chartOptions3: any;
    dataLayanan: any = []
    dataKomparasi: any = []
    chartKompar: any = []
    dataLokasi:any = []
    dataPlatform:any = []
    result: any = {
        real: 0,
        quot: 0,
        budget: 0,
        supplier: 0,
        fleet: 0,
        investor: 0,
        driver: 0,
    };
    dataJK: any
    jadwalDokter: any = []
    platform: any = []
    visitorChartOptions: any;
    namaLengkap: ''
    namaProfile = ''
    color2 =
        [
            "#42A5F5",
            "#66BB6A",
            "#FFA726",
            "#26C6DA",
            "#7E57C2",
            "#FF6384",
            "#4BC0C0",
            "#FFCE56",
            "#E7E9ED",
            "#36A2EB", '#7cb5ec', '#75b2a3', '#9ebfcc', '#acdda8', '#d7f4d2', '#ccf2e8',
            '#468499', '#088da5', '#00ced1', '#3399ff', '#00ff7f',
            '#b4eeb4', '#a0db8e', '#999999', '#6897bb', '#0099cc', '#3b5998',
            '#000080', '#191970', '#8a2be2', '#31698a', '#87ff8a', '#49e334',
            '#13ec30', '#7faf7a', '#408055', '#09790e']
    dataDepartemen: any = []
    totalData: any = 0
    hariKebelakang: any = 14
    basicOptions = {
       
        scales: {
            xAxes: [{
             
                gridLines: {
                    color: 'rgba(255,255,255,0.2)'
                }
            }],
            yAxes: [{
               
                gridLines: {
                    color: 'rgba(255,255,255,0.2)'
                }
            }]
        }
    };
    constructor(
        private apiService: ApiService,
        private productService: ProductService,
        private eventService: EventService,
        private auth: AuthService,
        private alert: AlertService,
        private breadcrumbService: AppBreadcrumbService
    ) {
        this.breadcrumbService.setItems([
            { label: 'Home' },
            // { label: 'Dashboard Pelayanan', routerLink: ['/'] },
        ]);
        this.namaLengkap = this.auth.getDataLoginUser().pegawai.namaLengkap
        this.namaProfile = 'BARRAH AIMAMA Aplication'
    }


    ngOnInit(): void {
        this.visitorChartOptions = {
            legend: {
                position: "top",
                align: "end",
            },
            responsive: true,
            hover: {
                mode: "index",
            },
            scales: {
                yAxes: [
                    {
                        // ticks: {
                        //     min: 0,
                        //     max: 1000,
                        // },
                        gridLines: {
                            display: false,
                        },
                    },
                ],
                xAxes: [
                    {
                        barPercentage: 0.5,
                        gridLines: {
                            display: false,
                        },
                    },
                ],
            },
        };

        this.load();

    }
    onSelect(e) {

        this.load();
    }
    load() {
        let date = "";
        let date1 = "";
        if (this.item.date.length > 1 && this.item.date[1] != null) {
            date = moment(this.item.date[0]).format("YYYY-MM-DD 00:00");
            date1 = moment(this.item.date[1]).format("YYYY-MM-DD 23:59");
        } else {
            date = moment(this.item.date[0]).format("YYYY-MM-DD 00:00");
            date1 = moment(this.item.date[0]).format("YYYY-MM-DD 23:59");
        }
        this.dataDepartemen = []
        this.totalData = 0
        this.apiService
            .get("eis/dashboard?start=" + date + "&end=" + date1 + "&trend=" + this.hariKebelakang)
            .subscribe((e) => {
                this.result = e;
                this.totalData = 0

                // for (let zz = 0; zz < e.jadwaldokter.length; zz++) {
                //   const element = e.jadwaldokter[zz];
                //   var nama = element.namalengkap.replace('dr. ', '')
                //   let ini = nama.split(' ')
                //   let init = nama.substr(0, 1)
                //   if (ini.length > 1) {
                //     init = init + ini[1].substr(0, 1)
                //   }
                //   element.initials = init
                // }
                // this.jadwalDokter = e.jadwaldokter
                let categories: any = [];
                let series1: any = [];
                let series2: any = [];
                let series3: any = [];
                if (e.trend != undefined) {
                    for (let x = 0; x < e.trend.length; x++) {
                        const element = e.trend[x];
                        categories.push(
                            moment(new Date(element.date)).format("DD MMM")
                        );
                        series1.push(element.totalbarang);
                        series2.push(element.revenue);

                    }

                    this.visitorChart = {
                        labels: categories,
                        type: 'bar',
                        options: {
                            maintainAspectRatio: false,
                            responsive: true,
                            legend: {
                                position: 'top'
                            },
                            animation: {
                                animateScale: true,
                                animateRotate: true
                            },
                            scales: {
                                // xAxes: {
                                //     display: true,
                                //     title: {
                                //         display: true,
                                //         text: 'Y Axis Label'
                                //     },
                                //     ticks: {
                                //         // Customize the Y-axis label format using the callback function
                                //         callback: function(value, index, values) {
                                //             return '$' + values; // Add a dollar sign to the labels
                                //         }
                                //     }
                                // },
                                yAxes: [{
                                    ticks: {
                                        beginAtZero: true,
                                        callback: function (value, index, values) {
                                            return '$' + values; // Add a dollar sign to the labels
                                        }
                                    }
                                }]
                            }
                        },
                        datasets: [

                            {
                                label: 'Number Of Sales',
                                data: series1,
                                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                                borderColor: 'rgba(255, 99, 132, 1)',
                                borderWidth: 1
                            },
                            {
                                label: 'Revenue',
                                data: series2,
                                backgroundColor: 'rgba(54, 162, 235, 0.2)',
                                borderColor: 'rgba(54, 162, 235, 1)',
                                borderWidth: 1,
                                type: 'line',
                            },


                        ],
                    };
                }

                this.timelineEvents = e.stok;
                this.listTransaction = e.penjualan;
                this.platform = e.platform;

                let seriesLayan = []
                let catLayan = []
                for (let y = 0; y < e.top5salesbyProduct.length; y++) {
                    const element = e.top5salesbyProduct[y];
                    seriesLayan.push(element.total)
                    catLayan.push(element.namaproduk)
                }

                this.dataLayanan = {
                    datasets: [{
                        data: seriesLayan,
                        backgroundColor: this.color2,
                        label: 'My dataset'
                    }],
                    labels: catLayan
                };

               
                

                let seriesLocation = []
                let catLocation = []
                for (let y = 0; y < e.location.length; y++) {
                    const element = e.location[y];
                    seriesLocation.push(element.total)
                    catLocation.push(element.namaruangan)
                }
                this.dataLokasi = {
                    datasets: [{
                        data: seriesLocation,
                        backgroundColor: this.color2,
                        label: 'SALES'
                    }],
                    labels: catLocation
                };
              
                let seriesPlat = []
                let catPlat = []
                for (let y = 0; y < e.platform.length; y++) {
                    const element = e.platform[y];
                    seriesPlat.push(element.totalfloat)
                    if(element.platform == 'OFFLINE'){
                        element.platform = 'OFLINE'
                    }
                    catPlat.push(element.platform)
                }
                this.dataPlatform = {
                    labels: catPlat,
                    datasets: [
                        {
                            data: seriesPlat,
                            backgroundColor: this.color2,
                            hoverBackgroundColor: this.color2
                        }]    
                    };
            });
    }
}

